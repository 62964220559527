import { Injectable } from '@angular/core';
import { ApplicationConstants } from '../app.constants';
import { LoadingService } from '../services/loading.service';
import { Alert } from '../interfaces';
import { NGXLogger } from "ngx-logger";
import { SearchAndFilterRequest } from './actions-store';
import { StateFn } from './StateFn';
import {
  AttachmentData,
  AttachmentWithRegistryConfig,
  MessageData, MessagesToUpdateWithRegistryConfig, ResponseStatus, ResponseWithState

} from '@smals/ebox-enterprise-client/dist/src/external-lib/InterfaceData';

import { EboxClient, EboxClientImpl } from "@smals/ebox-enterprise-client/dist/src/external-lib/ServiceClient/EboxClient";
import { AttachmentClientApi } from "@smals/ebox-enterprise-client/dist/src/external-lib/ServiceClient/AttachmentClientApi";
import { MessageRegistryStatus } from "@smals/ebox-enterprise-client/dist/src/federation/MessageRegistryFederator";
import { HandleExceptionService } from '../error/handle-exception-service.service';
import { NoCache } from "./NoCache";
import { EboxClientProvider } from "../services/EboxClientProvider";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

import { MessageListUrlParameters } from "../components/messages-from-ebox/MessageListUrlManagement";
import deepEqual from "deep-equal"
import { AuthenticateException, BusinessException, CommonException, ProviderException } from "../error/CommonException";
import { StoreService } from './store.service';
import { Folder, LANGUAGE_LOCALSTORAGE_KEY, MessageFilters, OptinStorage } from './store-data-interface';
import { ErrorType } from '@smals/ebox-enterprise-client/dist/src/external-lib/InterfaceData/ErrorType';
import { MessageActionStatusToPatch, MessagesMoveToOtherPartition, SenderOrganization } from '@smals/ebox-enterprise-client/dist/src/external-lib/client_api';
import { ConfigurationService } from '@smals/ngx-configuration-service';
import { EnterprisePreferences } from "../interfaces/enterprise-preferences";
import { CacheStorageService } from "../services/cacheStorage.service";
import { PacmanActionStore } from '../pacman/store/pacmanActionStore';
import { MessageActionType } from '../interfaces/enum/messageActionType';
import { Operation } from '@smals/ebox-enterprise-client/dist/src/external-lib/InterfaceData/Operation';
import { ResultDownload } from '../interfaces/download/result-download';


type SEARCH_DIRECTION = "next" | "prev" | null | undefined


@Injectable({
  providedIn: 'root'
})
export class ActionsService {


  constructor(
    private logger: NGXLogger,
    private loadingService: LoadingService,
    private _actionsStore: PacmanActionStore,
    private _handleErrorService: HandleExceptionService,
    private clientProvider: EboxClientProvider,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private storeService: StoreService,
    private stateFn: StateFn,
    private _configService: ConfigurationService,
    private _appConstant: ApplicationConstants,
    private cacheStorageService: CacheStorageService) {
  }


  private promiseSearchMessages(eboxClient: EboxClient, direction: SEARCH_DIRECTION): Promise<MessageData[]> {
    switch (direction) {
      case "prev": return eboxClient.prevPage();
      default: return eboxClient.nextPage();
    }
  }

  backSearchAndMessageFiltersAndPage(defaultRoute = "/messages") {
    this._actionsStore.clearStatusForms();

    this.setNavbarAndSearchBasedOnState(defaultRoute);
  }

  clearSearchAndMessageFiltersAndPage(defaultRoute = "/messages") {
    this._actionsStore.clearSearchAndMessageFiltersAndPage();
    this._actionsStore.clearStatusForms();

    this.setNavbarAndSearchBasedOnState(defaultRoute);
  }
  switchFolder(folder: Folder) {
    this._actionsStore.clearStatusForms();
    this._actionsStore.clearedMessagesSelected();
    if (folder === Folder.hide) {
      this.showMaskedMesssages();
    } else {
      this._actionsStore.clearSearchAndMessageFiltersAndPage();
      this._actionsStore.switchFolder(folder);
      this.setNavbarAndSearchBasedOnState();
    }


  }

  filterOnPartition(partitionName?: string) {
    const messageFilters = this.stateFn.getMessageFilters();
    if (this.stateFn.getCurrentFolder() != Folder.out) {
      messageFilters.recipientPartition = partitionName;
    } else {
      messageFilters.senderPartition = partitionName;
    }
    this.filterMessages(messageFilters);
  }

  filterOnMessageWithRequiredAction() {
    this._actionsStore.clearStatusForms();
    this._actionsStore.clearedMessagesSelected();
    this._actionsStore.clearSearchAndMessageFiltersAndPage();
    const messageFilters = this.stateFn.getMessageFilters();
    messageFilters.includeRequiredAction = true;
    this.filterMessages(messageFilters);
  }

  public setNavbarAndSearchBasedOnState(defaultRoute = "/messages") {
    const queryParams = MessageListUrlParameters.fromState(this.storeService.store.getValue(), this._actionsStore);

    if (window.location.pathname == '/messages' && deepEqual(this.activatedRoute.snapshot.queryParams, queryParams)) {
      this.searchMessages().then();
    }
    this.router.navigate([defaultRoute], { queryParams });
  }


  private checkExcludedProviderAndTokenExpired(clientResponse: any): any {

    if (this.storeService.store.getValue().tokenIsExpired) {
      this._handleErrorService.invalidatedToken();
    }

    /*
      active = 0,
      excluded = 1
      TODO  Generate exception with handleException
    */

    if (this.clientProvider.getSearchClient(false)) {
      const providersState = this.clientProvider.getSearchClient(false).state().providersState;
      let providerStatusForbidden = 0;
      let errorIsPresent;
      const providers: Record<string, ProviderException> = {};
      for (const provider in providersState) {
        const providerStatus: MessageRegistryStatus = providersState[provider] as MessageRegistryStatus;
        if (providerStatus.state === 1) {

          if (providers[providerStatus.error.registryId] == undefined) {
            providers[providerStatus.error.registryId] = new ProviderException(providerStatus.error.registryId, providerStatus.error.error.status, providerStatus.error.type.toString().toLowerCase());
          }

          errorIsPresent = true;
          if (providerStatus.error.type == ErrorType.FORBIDDEN) {
            providerStatusForbidden++;
          }

        }
      }
      if (providerStatusForbidden == Object.keys(providersState).length && errorIsPresent) {
        this._handleErrorService.forbiddenAccess();
      } else if (errorIsPresent) {
        this._handleErrorService.handlerProviderError(Object.values(providers));
      } else if (this.storeService.store.getValue().subsystems.error instanceof AuthenticateException && this.storeService.store.getValue().subsystems.error.code == 403) {
        this._actionsStore.removeError()
      }
    }

    return clientResponse;
  }



  // TODO: internal_to_be_merged_into_applySearchAndFilters
  private searchMessages(direction?: SEARCH_DIRECTION): Promise<boolean> {
    if (!this.stateFn.isAccessConsultation()) return;
    this.loadingService.start({ serviceRunStart: "searchMessages", delayedStart: false });
    // Reference data loading
    let referenceDataPromise: Promise<void> = null;
    if (this.stateFn.getAllReferencesData() == null) {
      referenceDataPromise = this.initAllReferenceData();
    }

    // Message search
    const parameters = this.stateFn.getParametersClient();

    if (parameters.search.folder == 'out') {
      parameters.search.messageType = this._configService.getEnvironmentVariable('sentboxConfig');
    }

    let client: EboxClient = null;
    const hasConsultationRightGeneral = this._actionsStore.hasConsultationRightGeneral();

    if (this._appConstant.isNullOrUndefined(direction)) {
      this.clientProvider.setSearchClient(new EboxClientImpl(hasConsultationRightGeneral, parameters, NoCache.inst, this.stateFn.getConfigClient(), this.clientProvider.resolveRecipient(this.stateFn.getUser())));
    }
    client = this.clientProvider.getSearchClient(hasConsultationRightGeneral);

    const promiseSearchMessages = this.promiseSearchMessages(client, direction)
      .then(results => {
        client.getEBoxNumberMessagesWithActionRequired(parameters.search.recipientPartition, false)
          .then(messageActionRequired => {
            const stateClient = client.state();
            this._actionsStore.searchResultUpdated({ items: results, totalItems: stateClient.totalSize, pageNumber: client.state().currentPageIndex + 1, totalItemsMessageAction: messageActionRequired.response });
            return Promise.resolve(null);
          })
      })
      .then(results => this.checkExcludedProviderAndTokenExpired(results))
      .catch(e => {
        this.logger.error('searchMessages %s', e);
        this.loadingService.stop('actionService  searchMessages error');
        this._handleErrorService.handlerError(e);
        return Promise.reject();
      });


    return Promise.all([referenceDataPromise, promiseSearchMessages]).then(() => {
      this.loadingService.stop('actionService  searchMessages success');
      return Promise.resolve(true);
    }).catch(e => {
      this.loadingService.stop('actionService  referenceDataPromise or promiseSearchMessages error');
      return Promise.resolve(false);
    });
  }



  detailMessage(messageId: string, providerId: string): Promise<any | MessageData> {
    this.loadingService.start({ serviceRunStart: "detailPage", delayedStart: false });
    let referenceDataPromise: Promise<void> = null;
    if (this.stateFn.getAllReferencesData() == null) {
      referenceDataPromise = this.initAllReferenceData();
    }
    const clientProvider = this.clientProvider.getSearchClient(false);
    const parameters = this.stateFn.getParametersClient();

    const promiseDetails: Promise<MessageData> = clientProvider.detail(messageId, providerId)
      .then(results => this.checkExcludedProviderAndTokenExpired(results))
      .then((result: MessageData) => {
        clientProvider.getEBoxNumberMessagesWithActionRequired(parameters.search.recipientPartition, false)
          .then(messageActionRequired => this._actionsStore.updateNbMessagesActionRequired(messageActionRequired.response)).finally(() => result);

        return result;
      }

      ).catch(e => {
        this.loadingService.stop('actionService  detail error');
        if (e['operation'] == undefined) e['operation'] = Operation.GET_MESSAGE;
        if (e.code == 504 && e["registryId"] == undefined) e["registryId"] = providerId;
        this._handleErrorService.handlerError(e);
        return Promise.reject();
      }).finally(() => this.loadingService.stop('actionService  detail success'));


    return Promise.all([referenceDataPromise, promiseDetails]).then(resolve => resolve[1]
    ).catch(e => { this.logger.error("actionService error referenceDataPromise or promiseSearchMessages  %s", (typeof (e) !== "object") ? JSON.stringify(e) : e); this.loadingService.stop('actionService  referenceDataPromise or promiseSearchMessages error') });
  }
  getSenderOrganization(organizationId, providerId): Promise<any | SenderOrganization> {
    return this.clientProvider.getSearchClient(false).getSenderOrganization(organizationId, providerId)
      .then(results => this.checkExcludedProviderAndTokenExpired(results))
      .then((result: string) => result)
      .catch(e => null);

  }
  bodyContentOfMessage(messageId: string, providerId: string, activeLoading = true): Promise<string> {
    if (activeLoading) this.loadingService.start({ serviceRunStart: "bodyContentOfMessage", delayedStart: false });
    return this.clientProvider.getSearchClient(false).bodyContent(messageId, providerId)
      .then(results => this.checkExcludedProviderAndTokenExpired(results))
      .then((result: string) => {
        if (activeLoading) this.loadingService.stop('actionService  detail success');

        return result;
      }).catch(e => { if (activeLoading) this.loadingService.stop('actionService  detail error'); return null; });
  }

  downloadMessage(attachmentData: AttachmentData, registryId: string): Promise<boolean> {
    const attachment: AttachmentWithRegistryConfig = { ...attachmentData, ...this.stateFn.getConfigClient(), providerRegistryId: registryId };
    this.loadingService.start({ serviceRunStart: "downloadMessage", delayedStart: false });
    return this.clientProvider.getSearchClient(false).download(attachment)
      .then(results => this.checkExcludedProviderAndTokenExpired(results))
      .then(downloadOk => {
        this.loadingService.stop('actionService  downloadMessage success');

        return downloadOk;
      })
      .catch(e => {
        this.checkExcludedProviderAndTokenExpired(null);
        e.originalException = e.error;
        e.message = "document";
        this._handleErrorService.handlerError(e);
        this.loadingService.stop('actionService  downloadMessage error');
        return Promise.reject(e);
      }).finally(() => this.loadingService.stop("finaly stop downaloadMessage"))

  }

  getAttachmentContent(attachmentData: AttachmentData, registryId: string): Promise<ResultDownload> {
    const attachment: AttachmentWithRegistryConfig = { ...attachmentData, ...this.stateFn.getConfigClient(), providerRegistryId: registryId };
    return new AttachmentClientApi().getAttachmentContent(attachment)
      .then(results => this.checkExcludedProviderAndTokenExpired(results)).then(content => Promise.resolve({ fileName: attachment.fileName, providerId: registryId, content: content })).catch(error => Promise.resolve({ fileName: attachment.fileName, providerId: registryId, error: error } as ResultDownload));
  }

  initAllReferenceData(): Promise<void> {
    if (this.stateFn.getAllReferencesData() == null && this.stateFn.isAccessConsultation()) {
      return this.clientProvider.getSearchClient(false).getAllReferenceData()
        .then(results => this.checkExcludedProviderAndTokenExpired(results))
        .then(result => {
          this._actionsStore.updateReferencesData(result);
          return Promise.resolve(null);
        }).catch(e => {
          this.logger.error("Error InitAllReferenceData %s", (typeof (e) !== "object") ? JSON.stringify(e) : e);
          Promise.resolve(null)
        });
    }
  }


  changeLanguage(language: string) {

    if (language && (language === 'fr' || language === 'nl' || language === 'en' || language === 'de')) {
      // do nothing
    } else {
      this.logger.error(language + " is not a valid language");
      return;
    }
    this.translateService.use(language);
    localStorage.setItem(LANGUAGE_LOCALSTORAGE_KEY, language);
    // TODO: Why set the default if we just used USE here?
    this.translateService.setDefaultLang(language);

    this._actionsStore.changeLanguage(language);


    //add check for resetPage only if we are on listMessagePage
    if (this.storeService.store.getValue().searchState.searchResults != null && this.router.url.indexOf('messages') != -1) {
      this.setNavbarAndSearchBasedOnState();
    }



  }

  filterMessages(filters: Partial<MessageFilters>) {
    this._actionsStore.filterMessages(filters);
    this.setNavbarAndSearchBasedOnState();
  }

  filterSelectedMessages(selections: { value: string, status: boolean }[]) {

    const storeSelectedMessages = this.storeService.store.getValue().userActions.messagesSelected;
    const newSelections: string[] = storeSelectedMessages.slice();
    selections.forEach((select: { value, status }) => {
      const index = newSelections.indexOf(select.value);
      if (index == -1) {
        if (select.status) newSelections.push(select.value);
      } else {
        if (!select.status) {
          newSelections.splice(index, 1);
        }
      }

    })
    this._actionsStore.updateMessagesSelected(newSelections);

  }

  // TODO: no promesse should be returned
  public updateVisibility(messagesToUpdate: MessagesToUpdateWithRegistryConfig): Promise<Response> {

    const eboxClient = this.clientProvider.getSearchClient(false);

    return eboxClient.updateVisibility(messagesToUpdate)
      .then(results => this.checkExcludedProviderAndTokenExpired(results)
      )
      .then(resp => {
        this.searchMessages().then();
        return Promise.resolve(resp);
      })
      .catch(error => {
        this._handleErrorService.handlerError(error); return Promise.reject()
      });

  }



  public initializeAlertMessage() {
    if (!this.stateFn.isAccessConsultation()) return;
    if (this.stateFn.isAccessOnlyManualPublication()) return;
    const d: Date = new Date();
    d.setDate(d.getDate() + ApplicationConstants.offDay);
    //  this._actionsStore.removeAlert('warning', 'recommend');
    //  this._actionsStore.removeAlert('warning', 'expired');

    const hasConsultationRightGeneral = this._actionsStore.hasConsultationRightGeneral();
    const eboxClientRegisteredP = this.clientProvider.getSearchClient(hasConsultationRightGeneral).getRegisteredMailUnReadMessage();
    const eboxClientExpiredP = this.clientProvider.getSearchClient(hasConsultationRightGeneral).getExpiringUnReadMessages(ApplicationConstants.offDay);

    Promise.all([
      eboxClientRegisteredP.then((responseRegisteredP: ResponseWithState) => { const result = {}; result['filterName'] = 'recommend'; result['totalSize'] = responseRegisteredP.response; return result; }),
      eboxClientExpiredP.then((responseExpiredP: ResponseWithState) => { const result = {}; result['filterName'] = 'expired'; result['totalSize'] = responseExpiredP.response; return result; })])
      .then(results => this.checkExcludedProviderAndTokenExpired(results))
      .then(
        r => {
          const alerts: Alert[] = [];
          r.forEach(element => {
            alerts.push({ nameOfResult: element['filterName'], count: element['totalSize'] });
          });

          this._actionsStore.addAlerts('warning', alerts);
        }
      );
  }


  changePageSize(data: number) {
    this._actionsStore.changePageSize(data);
    this.setNavbarAndSearchBasedOnState();
  }

  updateEnterprisePreferences(e: EnterprisePreferences) {
    const storage = this.cacheStorageService.getStorage('ebox_' + e.enterpriseNumber) as OptinStorage;
    this._actionsStore.updateEnterprisePreferences(e, storage == undefined ? null : storage.lastReminderDate);
    this.updateLocalStorage();
  }

  updateOptin() {
    this._actionsStore.updateOptin(true);
    this.updateLocalStorage();
  }

  updatePartitionPolicy() {
    this._actionsStore.updatePartitionPolicy(true);
  }

  updateDocConsumerPolicy() {
    this._actionsStore.updateDocConsumerPolicy(true);
  }

  convertUserToOptinStorage() {
    const user = this.storeService.store.getValue().userSession.user;
    return {
      firstConnectionDate: user.firstConnectionDate,
      lastReminderDate: user.lastReminderDate,
      hasOptin: user.exclusivelyEbox
    } as OptinStorage
  }


  updateLocalStorage() {
    this.cacheStorageService.setLocaleStorage(this.stateFn.getUser().cbeNumber, this.convertUserToOptinStorage());
  }

  updateLastRemindDate(lastRemindDate: Date) {
    this._actionsStore.updateLastRemindDate(lastRemindDate);
    this.updateLocalStorage();
  }



  setIncludeExpiringMessagesFilter(v: boolean) {
    const filter = this.storeService.store.getValue().messageFilters;
    this.filterMessages({
      ...filter,
      includeExpiringMessages: v,
    })
  }

  setIncludeRegisteredMessagesFilter(v: boolean) {
    const filter = this.storeService.store.getValue().messageFilters;
    this.filterMessages({
      ...filter,
      includeRegisteredMessages: v,
    })
  }

  setIncludeReadMessagesFilter(v: boolean) {
    const filter = this.storeService.store.getValue().messageFilters;
    this.filterMessages({
      ...filter,
      includeReadMessages: v,
    })
  }

  setIncludeUnreadMessagesFilter(v: boolean) {
    const filter = this.storeService.store.getValue().messageFilters;
    this.filterMessages({
      ...filter,
      includeUnreadMessages: v,
    })
  }

  setPublishedFromMessagesFilter(v: Date | null) {
    const filter = this.storeService.store.getValue().messageFilters;
    this.filterMessages({
      ...filter,
      publicationDateFrom: v,
    })
  }

  setPublishedToMessagesFilter(v: Date | null) {
    const filter = this.storeService.store.getValue().messageFilters;
    this.filterMessages({
      ...filter,
      publicationDateTo: v,
    })
  }

  setSenderOrganizationFilter(v: string | null) {
    const filter = this.storeService.store.getValue().messageFilters;
    this.filterMessages({
      ...filter,
      senderOrganizationSelection: v,
    })
  }

  setSenderApplicationFilter(v: string | null) {
    const filter = this.storeService.store.getValue().messageFilters;
    this.filterMessages({
      ...filter,
      senderApplicationSelection: v,
    })
  }

  startNewSearch(terms: string) {
    this._actionsStore.startNewSearch(terms);
    this.setNavbarAndSearchBasedOnState();
  }

  setMobileDevice(isMobile: boolean) {
    this._actionsStore.setMobileDevice(isMobile);
  }

  loadSearchParamsFromNavBarAndSearch(): Promise<boolean> {

    if (window.location.pathname == "/messages") {
      const queryParams: URLSearchParams = new URLSearchParams(window.location.search);
      const params: SearchAndFilterRequest = MessageListUrlParameters.toStateAndSearch(queryParams);
      if (params != null) {
        this._actionsStore.loadSearchParamsFromNavBar(params);
        return this.searchMessages().then(resp => Promise.resolve(true)).catch(error => Promise.resolve(false));
      } else {
        this.clearSearchAndMessageFiltersAndPage();
        return Promise.resolve(true);
      }
    } else {
      return Promise.resolve(false);
    }
  }

  nextSearchResultPage() {
    this._actionsStore.clearedMessagesSelected();
    this.searchMessages("next").then();
  }

  prevSearchResultPage() {
    this._actionsStore.clearedMessagesSelected();
    this.searchMessages("prev").then();
  }

  showMaskedMesssages() {
    this._actionsStore.showMaskedMessages();
    this.setNavbarAndSearchBasedOnState();
  }

  showExpiringMessages() {
    this._actionsStore.showExpiringMessages()
    this.setNavbarAndSearchBasedOnState();
  }

  showRegisteredMessages() {
    this._actionsStore.showRegisteredMessages()
    this.setNavbarAndSearchBasedOnState();
  }

  updateMessageActionStatus(messageActionStatusToPatch: MessageActionStatusToPatch, message: MessageData): Promise<Response | void> {
    return this.clientProvider.getSearchClient(false).patchMessageActionStatus(message.messageId, MessageActionType.MESSAGE_WITH_ACTION_REQUIRED, message.registryId, messageActionStatusToPatch)
      .then(response => Promise.resolve(response)

      )
      .catch(error => {
        error["registryId"] = message.registryId;
        this._handleErrorService.handlerError(error);
        return Promise.reject(error);
      });
  }

  getNbMessagesWithRequiredAction(): Promise<number> {
    return this.clientProvider.getSearchClient(false).getEBoxNumberMessagesWithActionRequired()
      .then(response => Promise.resolve(response.response))
      .catch(error => Promise.reject(error));
  }

  moveMessagesIdToPartition(messagesToMove: Record<string, MessagesMoveToOtherPartition>): Promise<boolean> {
    this.loadingService.start({ serviceRunStart: "moveMessagesIdToPartition", delayedStart: false })
    return this.clientProvider.getSearchClient(false).changePartition(messagesToMove).then((response: ResponseWithState) => {

      if (response.globalStatus == ResponseStatus.partial || response.globalStatus == ResponseStatus.fail) {
        throw response;
      }
      return Promise.resolve(true)
    }
    ).catch(
      (error: ResponseWithState) => {
        const errorBusiness = new BusinessException({ message: Operation.MOVE_MESSAGES, error: error, redirect: false, code: 404 });
        (<CommonException>errorBusiness).type = Operation.MOVE_MESSAGES;
        const listErrorProviderId: string[] = Object.keys(error.state);
        errorBusiness['providerRegistryId'] = listErrorProviderId;

        if (error.globalStatus == ResponseStatus.partial) {
          errorBusiness.message = "movePartition.partial";
        } else if (error.globalStatus == ResponseStatus.fail) {
          errorBusiness.message = "movePartition.fail";

        }
        this._handleErrorService.handlerError(errorBusiness);
        this.loadingService.stop("moveMessagesIdToPartition");
        return Promise.reject(error);

      });

  }

  setAdminStatus(isAdmin: boolean) {
    const oldStore = this.storeService.store.getValue();
    this.storeService.store.next({
      ...oldStore,
      userSession: {
        ...oldStore.userSession,
        isAdmin: isAdmin
      }
    });
  }

}


import { IConfiguration } from '@smals/ngx-configuration-service';
import { NgxLoggerLevel } from 'ngx-logger';
import { OpenIdEnvironmentConfig } from './auth/open-id-configurator';



export interface AppEnvironmentVariables {
    readonly urlBackendEnterpriseRest: string;

}
export const LINES_PER_PAGE = 20;
export const INITIAL_FILTER = 'status:TODO';


export class Scopes {
    static readonly EBOX_PUBLICATION_WEB = 'scope:document:management:consult:ws-eboxrestentreprise:publicationweb';
    static readonly EBOX_RESPONSIBLE = 'scope:document:management:consult:ws-eboxrestentreprise:eboxresponsible';
    static readonly EBOX_ENTERPRISE_ADMIN = 'scope:document:management:consult:ws-eboxrestentreprise:eboxenterpriseadmin';
    static readonly EBOX_MANDATARY_ADMIN = 'scope:document:management:consult:ws-eboxrestentreprise:eboxmandataryadmin';
    static readonly EBOX_ENTERPRISE_USER = 'scope:document:management:consult:ws-eboxrestentreprise:eboxenterpriseuser';
    static readonly EBOX_MANDATARY_USER = 'scope:document:management:consult:ws-eboxrestentreprise:eboxmandataryuser';
    static readonly ADMIN = 'scope:ebox:enterprise:profile';
}


/* can't use spread for reuse a defaultVariable because aot ignore spread in injectionModule
    fix: override configurationService in module by a configurationServiceExtended who use a defaultConfig config.variables[env].defaultConfig[key]

*/

export const testEnvConf = {
    urlBackendEnterpriseRest: 'https://services-test.socialsecurity.be/REST/ebox/enterprise/messageRegistry/v3/',
    urlWebAppBackend: 'https://services-test.socialsecurity.be/REST/ebox/enterprise/backend/v1',
    urlProviderRegistry: 'https://services-test.socialsecurity.be/REST/ebox/enterprise/federation/documentProviderRegistry/v1/messageRegistries/',
    urlPacmanConsult: 'https://services-test.socialsecurity.be/REST/ebox/enterprise/partition/v1',
    urlPacmanAdmin: 'https://services-test.socialsecurity.be/REST/ebox/enterprise/partitionManagement/v2',
    // urlWebAppBackend: 'https://services-test.socialsecurity.be/REST/ebox/enterprise/backend/v1/',
    urlUman: "https://uman.int.socialsecurity.be/uman",
    urlWebsiteInfo: 'https://eboxenterprise.tst.socialsecurity.be/info',
    urlMonitoring: "https://services-test.socialsecurity.be/REST/ebox/enterprise/webAppSettings/v1",
    end_session_endpoint: 'https://loginint.socialsecurity.be/idp/logout',
    urlSocialSecurity: 'http://website.smals-mvm.be:7023/',
    // logLevel: NgxLoggerLevel.DEBUG,
    securityRole: '',
    loadingPeriod: 1000,
    timeoutByRequest: 15000,
    timeoutConnection: 30000,
    old_ebox: 'http://ebox12-test.cloud.extranetdc.be:2005/app009/e-Box/Enterprise/',
    env_registry: 'test',
    remindDate: 30,
    openIdEnvironmentConfig: {
        autoUserInfo: true,
        responseType: 'code',
        log_console_debug_active: true,
        stsServer: 'https://oauth-v5.int.socialsecurity.be/oauth2/authorize',
        post_logout_redirect_uri: 'https://loginint.socialsecurity.be/idp/logout',
        wellKnownEndPoints: {
            issuer: 'https://oauth-v5.int.socialsecurity.be',
            authorization_endpoint: 'https://oauth-v5.int.socialsecurity.be/oauth2/authorize',
            jwks_uri: 'https://privateservices-int.socialsecurity.be/REST/oauth/v5/jwks',
            token_endpoint: 'https://privateservices-int.socialsecurity.be/REST/oauth/v5/token',
            userinfo_endpoint: 'https://professionalservices-int.socialsecurity.be/REST/openIdConnect/v1/userinfo',
        }
    } as OpenIdEnvironmentConfig,
    maxAPIVersionSupported: '3.0',
    sentboxConfig: [{ id: 'ReplyMessage', registryid: 'socsec_v1' }, { id: 'MESSAGE_TYPE_MANUAL_PUB', registryid: 'socsec_v1' }]
}

export const config: IConfiguration = {
    environments: [
        {
            env: 'local',
            domain: ['localhost']
        },
        {
            env: 'test',
            domain: [
                'eboxenterprise.tst.socialsecurity.be']
        },
        {
            env: 'testChannelA',
            domain: ['eboxenterprise-channel-a.tst.socialsecurity.be']
        },
        {
            env: 'testChannelB',
            domain: ['eboxenterprise-channel-b.tst.socialsecurity.be']
        },
        {
            env: 'testChannelC',
            domain: ['eboxenterprise-channel-c.tst.socialsecurity.be']
        },
        {
            env: 'int',
            domain: [
                'eboxenterprise.int.socialsecurity.be',
                'eboxenterprise-channel-a.int.socialsecurity.be',
                'eboxenterprise-channel-b.int.socialsecurity.be',
                'eboxenterprise-channel-c.int.socialsecurity.be'
            ]
        },
        {
            env: 'acc',
            domain: [
                'wwwacc.app.eboxenterprise.be',
                'eboxenterprise.acc.socialsecurity.be',
                'wwwacc.eboxenterprise-channel-a.be',
                'wwwacc.eboxenterprise-channel-b.be',
                'wwwacc.eboxenterprise-channel-c.be',
                'eboxenterprise-channel-a.acc.socialsecurity.be',
                'eboxenterprise-channel-b.acc.socialsecurity.be',
                'eboxenterprise-channel-c.acc.socialsecurity.be'
            ]
        },
        {
            env: 'staging',
            domain: [
                'eboxenterprise.sim.socialsecurity.be',
                'eboxenterprise-channel-a.sim.socialsecurity.be',
                'eboxenterprise-channel-b.sim.socialsecurity.be',
                'eboxenterprise-channel-c.sim.socialsecurity.be'
            ]
        },
        {
            env: 'prod',
            domain: [
                'app.eboxenterprise.be',
                'eboxenterprise.be',
                'eboxenterprise-channel-a.socialsecurity.be',
                'eboxenterprise-channel-b.socialsecurity.be',
                'eboxenterprise-channel-c.socialsecurity.be'
            ]
        }
    ],
    variables: {
        local: {
            urlBackendEnterpriseRest: 'https://services-test.socialsecurity.be/REST/ebox/enterprise/messageRegistry/v3/',
            urlWebAppBackend: 'https://services-test.socialsecurity.be/REST/ebox/enterprise/backend/v1',
            urlProviderRegistry: 'https://document-provider-registry.tst.socialsecurity.be/REST/ebox/enterprise/federation/documentProviderRegistry/v1/messageRegistries/',
            urlWebsiteInfo: 'http://localhost:4200/info',
            // urlWebsiteInfo: 'https://ebox-enterprise-website.test.paas.socialsecurity.be/',
            urlSocialSecurity: 'https://website.smals-mvm.be:7023/',
            urlCsam: 'https://camregistration.int.csam.be/ui/camregistration/xhtml/index.xhtml?processType=SelfAssign',
            urlPacmanConsult: 'https://services-test.socialsecurity.be/REST/ebox/enterprise/partition/v1',
            urlPacmanAdmin: 'https://services-test.socialsecurity.be/REST/ebox/enterprise/partitionManagement/v2',
            urlUman: "https://uman.int.socialsecurity.be/uman",
            urlMonitoring: "https://services-test.socialsecurity.be/REST/ebox/enterprise/webAppSettings/v1",
            frontendUrl: 'http://localhost:4200',
            logLevel: NgxLoggerLevel.DEBUG,
            securityRole: '',
            loadingPeriod: 1000,
            timeoutByRequest: 15000,
            timeoutConnection: 20000,
            end_session_endpoint: 'https://loginint.socialsecurity.be/idp/logout',
            authority: 'https://services-int.socialsecurity.be',
            old_ebox: 'https://ebox12-test.cloud.extranetdc.be:2005/app009/e-Box/Enterprise/',
            env_registry: 'local',
            sentboxConfig: [{ id: 'ReplyMessage', registryid: 'socsec_v1' }, { id: 'MESSAGE_TYPE_MANUAL_PUB', registryid: 'socsec_v1' }],
            showErrorInConsoleLog: true,
            maxAPIVersionSupported: "3.0",
            remindDate: 30,
            openIdEnvironmentConfig: {
                autoUserInfo: true,
                responseType: 'code',
                log_console_debug_active: true,
                stsServer: 'https://oauth-v5.int.socialsecurity.be/oauth2/authorize',
                post_logout_redirect_uri: 'https://loginint.socialsecurity.be/idp/logout',
                wellKnownEndPoints: {
                    issuer: 'https://oauth-v5.int.socialsecurity.be',
                    authorization_endpoint: 'https://oauth-v5.int.socialsecurity.be/oauth2/authorize',
                    jwks_uri: 'https://privateservices-int.socialsecurity.be/REST/oauth/v5/jwks',
                    token_endpoint: 'https://privateservices-int.socialsecurity.be/REST/oauth/v5/token',
                    userinfo_endpoint: 'https://professionalservices-int.socialsecurity.be/REST/openIdConnect/v1/userinfo',
                }
            } as OpenIdEnvironmentConfig


        },
        test: testEnvConf,
        testChannelA: { defaultConfig: testEnvConf, urlWebsiteInfo: 'https://eboxenterprise-channel-a.tst.socialsecurity.be/info' },
        testChannelB: { defaultConfig: testEnvConf, urlWebsiteInfo: 'https://eboxenterprise-channel-b.tst.socialsecurity.be/info' },
        testChannelC: { defaultConfig: testEnvConf, urlWebsiteInfo: 'https://eboxenterprise-channel-c.tst.socialsecurity.be/info' },

        int: {
            sentboxConfig: [{ id: 'ReplyMessage', registryid: 'socsec_v1' }, { id: 'MESSAGE_TYPE_MANUAL_PUB', registryid: 'socsec_v1' }],
            urlBackendEnterpriseRest: 'https://services-int.socialsecurity.be/REST/ebox/enterprise/messageRegistry/v3/',
            urlWebAppBackend: 'https://services-int.socialsecurity.be/REST/ebox/enterprise/backend/v1',
            // urlWebAppBackend: 'https://services-int.socialsecurity.be/REST/ebox/enterprise/backend/v1/',
            urlProviderRegistry: 'https://services-int.socialsecurity.be/REST/ebox/enterprise/federation/documentProviderRegistry/v1/messageRegistries/',


            urlWebsiteInfo: 'https://info-eboxenterprise.test.ext.socialsecurity.be',
            end_session_endpoint: 'https://loginint.socialsecurity.be/idp/logout',
            urlSocialSecurity: 'https://website.smals-mvm.be:7023/',
            urlCsam: 'https://camregistration.int.csam.be/ui/camregistration/xhtml/index.xhtml?processType=SelfAssign',
            urlUman: "https://uman.int.socialsecurity.be/uman",
            urlMonitoring: "https://services-int.socialsecurity.be/REST/ebox/enterprise/webAppSettings/v1",
            logLevel: NgxLoggerLevel.INFO,
            securityRole: '',
            timeoutByRequest: 15000,
            timeoutConnection: 20000,
            loadingPeriod: 1000,

            openIdEnvironmentConfig: {
                autoUserInfo: true,
                responseType: 'code',
                log_console_debug_active: true,
                stsServer: 'https://oauth-v5.int.socialsecurity.be/oauth2/authorize',
                post_logout_redirect_uri: 'https://loginint.socialsecurity.be/idp/logout',
                wellKnownEndPoints: {
                    issuer: 'https://oauth-v5.int.socialsecurity.be',
                    authorization_endpoint: 'https://oauth-v5.int.socialsecurity.be/oauth2/authorize',
                    jwks_uri: 'https://privateservices-int.socialsecurity.be/REST/oauth/v5/jwks',
                    token_endpoint: 'https://privateservices-int.socialsecurity.be/REST/oauth/v5/token',
                    userinfo_endpoint: 'https://professionalservices-int.socialsecurity.be/REST/openIdConnect/v1/userinfo',
                }
            } as OpenIdEnvironmentConfig,
            urlPacmanConsult: 'https://services-int.socialsecurity.be/REST/ebox/enterprise/partition/v1',
            urlPacmanAdmin: 'https://services-int.socialsecurity.be/REST/ebox/enterprise/partitionManagement/v2',
            old_ebox: 'https://wwwint.socialsecurity.be/app009/e-Box/Enterprise/',
            env_registry: 'int',
            maxAPIVersionSupported: '3.0',
            remindDate: 30
        },
        acc: {
            urlBackendEnterpriseRest: 'https://services-acpt.socialsecurity.be/REST/ebox/enterprise/messageRegistry/v3/',
            urlWebAppBackend: 'https://services-acpt.socialsecurity.be/REST/ebox/enterprise/backend/v1',
            urlProviderRegistry: 'https://services-acpt.socialsecurity.be/REST/ebox/enterprise/federation/documentProviderRegistry/v1/messageRegistries/',
            // urlWebAppBackend: 'https://services-acpt.socialsecurity.be/REST/ebox/enterprise/backend/v1/',
            urlWebsiteInfo: 'https://wwwacc.eboxenterprise.be',
            urlSocialSecurity: 'https://wwwacc.socialsecurity.be/',
            urlCsam: 'https://camregistration.acc.csam.be/ui/camregistration/xhtml/index.xhtml?processType=SelfAssign',
            urlUman: "https://uman.acc.socialsecurity.be/uman",
            urlMonitoring: "https://services-acpt.socialsecurity.be/REST/ebox/enterprise/webAppSettings/v1",
            end_session_endpoint: 'https://loginacc.socialsecurity.be/idp/logout',
            logLevel: NgxLoggerLevel.WARN,
            securityRole: '',
            timeoutByRequest: 50000,
            timeoutConnection: 60000,
            loadingPeriod: 1000,
            openIdEnvironmentConfig: {
                autoUserInfo: true,
                responseType: 'code',
                log_console_debug_active: true,
                stsServer: 'https://oauth-v5.acc.socialsecurity.be/oauth2/authorize',
                post_logout_redirect_uri: 'https://loginacc.socialsecurity.be/idp/logout',
                wellKnownEndPoints: {
                    issuer: 'https://oauth-v5.acc.socialsecurity.be',
                    jwks_uri: 'https://services-acpt.socialsecurity.be/REST/oauth/v5/jwks',
                    authorization_endpoint: 'https://oauth-v5.acc.socialsecurity.be/oauth2/authorize',
                    token_endpoint: 'https://services-acpt.socialsecurity.be/REST/oauth/v5/token',
                    userinfo_endpoint: 'https://services-acpt.socialsecurity.be/REST/openIdConnect/v1/userinfo',
                }
            } as OpenIdEnvironmentConfig,
            old_ebox: 'https://wwwacc.socsec.eboxenterprise.be/',
            urlPacmanConsult: 'https://services-acpt.socialsecurity.be/REST/ebox/enterprise/partition/v1',
            urlPacmanAdmin: 'https://services-acpt.socialsecurity.be/REST/ebox/enterprise/partitionManagement/v2',
            env_registry: 'acc',
            remindDate: 30,
            sentboxConfig: [{ id: 'ReplyMessage', registryid: 'socsec_v1' }, { id: 'MESSAGE_TYPE_MANUAL_PUB', registryid: 'socsec_v1' }],
            maxAPIVersionSupported: '3.0'
        },
        staging: {
            urlBackendEnterpriseRest: 'https://services.socialsecurity.be/REST/ebox/enterprise/messageRegistry/v3/',
            urlWebAppBackend: 'https://services.socialsecurity.be/REST/ebox/enterprise/backend/v1',
            urlProviderRegistry: 'https://services.socialsecurity.be/REST/ebox/enterprise/federation/documentProviderRegistry/v1/messageRegistries/',
            // urlWebAppBackend: 'https://services.socialsecurity.be/REST/ebox/enterprise/backend/v1/',
            urlWebsiteInfo: 'https://info.staging.eboxenterprise.be',
            end_session_endpoint: 'https://login.socialsecurity.be/idp/logout',
            urlSocialSecurity: 'https://www.socialsecurity.be/',
            urlUman: "https://uman.socialsecurity.be/uman",
            urlMonitoring: "https://services.socialsecurity.be/REST/ebox/enterprise/webAppSettings/v1",
            // logLevel: NgxLoggerLevel.WARN,
            securityRole: '',
            timeoutByRequest: 50000,
            timeoutConnection: 60000,
            loadingPeriod: 1000,
            openIdEnvironmentConfig: {
                autoUserInfo: true,
                responseType: 'code',
                log_console_debug_active: true,
                stsServer: 'https://oauth-v5.acc.socialsecurity.be/oauth2/authorize',
                post_logout_redirect_uri: 'https://loginacc.socialsecurity.be/idp/logout',
                wellKnownEndPoints: {
                    issuer: 'https://oauth-v5.acc.socialsecurity.be',
                    jwks_uri: 'https://services-acpt.socialsecurity.be/REST/oauth/v5/jwks',
                    authorization_endpoint: 'https://oauth-v5.acc.socialsecurity.be/oauth2/authorize',
                    token_endpoint: 'https://services-acpt.socialsecurity.be/REST/oauth/v5/token',
                    userinfo_endpoint: 'https://services-acpt.socialsecurity.be/REST/openIdConnect/v1/userinfo',
                }
            } as OpenIdEnvironmentConfig,
            old_ebox: 'https://socsec.eboxenterprise.be/',
            env_registry: 'prod',
            urlPacmanConsult: 'https://services.socialsecurity.be/REST/ebox/enterprise/partition/v1',
            urlPacmanAdmin: 'https://services.socialsecurity.be/REST/ebox/enterprise/partitionManagement/v2',
            remindDate: 30,
            sentboxConfig: [{ id: 'ReplyMessage', registryid: 'socsec_v1' }, { id: 'MESSAGE_TYPE_MANUAL_PUB', registryid: 'socsec_v1' }],
            maxAPIVersionSupported: '3.0'
        },
        prod: {
            urlBackendEnterpriseRest: 'https://services.socialsecurity.be/REST/ebox/enterprise/messageRegistry/v3/',
            urlWebAppBackend: 'https://services.socialsecurity.be/REST/ebox/enterprise/backend/v1',
            urlProviderRegistry: 'https://services.socialsecurity.be/REST/ebox/enterprise/federation/documentProviderRegistry/v1/messageRegistries/',

            // urlWebAppBackend: 'https://services.socialsecurity.be/REST/ebox/enterprise/backend/v1/',
            urlWebsiteInfo: 'https://www.eboxenterprise.be',
            end_session_endpoint: "https://login.socialsecurity.be/idp/logout",
            urlSocialSecurity: 'https://www.socialsecurity.be/',
            urlCsam: 'https://camregistration.csam.be/ui/camregistration/xhtml/index.xhtml?processType=SelfAssign',
            urlUman: "https://uman.socialsecurity.be/uman",
            urlMonitoring: "https://services.socialsecurity.be/REST/ebox/enterprise/webAppSettings/v1",
            logLevel: NgxLoggerLevel.WARN,
            securityRole: '',
            timeoutByRequest: 50000,
            timeoutConnection: 60000,
            loadingPeriod: 1000,
            openIdEnvironmentConfig: {
                autoUserInfo: true,
                responseType: 'code',
                log_console_debug_active: true,
                stsServer: 'https://oauth-v5.socialsecurity.be/oauth2/authorize',
                post_logout_redirect_uri: 'https://login.socialsecurity.be/idp/logout',
                wellKnownEndPoints: {
                    issuer: 'https://oauth-v5.socialsecurity.be',
                    jwks_uri: 'https://services.socialsecurity.be/REST/oauth/v5/jwks',
                    authorization_endpoint: 'https://oauth-v5.socialsecurity.be/oauth2/authorize',
                    token_endpoint: 'https://services.socialsecurity.be/REST/oauth/v5/token',
                    userinfo_endpoint: 'https://services.socialsecurity.be/REST/openIdConnect/v1/userinfo',
                }
            } as OpenIdEnvironmentConfig,
            old_ebox: 'https://socsec.eboxenterprise.be/',
            env_registry: 'prod',
            urlPacmanConsult: 'https://services.socialsecurity.be/REST/ebox/enterprise/partition/v1',
            urlPacmanAdmin: 'https://services.socialsecurity.be/REST/ebox/enterprise/partitionManagement/v2',
            remindDate: 30,
            sentboxConfig: [{ id: 'ReplyMessage', registryid: 'socsec_v1' }, { id: 'MESSAGE_TYPE_MANUAL_PUB', registryid: 'socsec_v1' }],
            maxAPIVersionSupported: '3.0'
        }
    }
}
